html {
  scroll-behavior: smooth;
  width: 100vw;
}

body {
  background-image: url(./assets/bg-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 1850px;
  width: 100vw;
  font-family: "Inter", sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 38px;
}
h3 {
  font-size: 20px;
  color: #1E1E1E;
}
h4 {
  color: #B2B2B2;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
p {
  font-size: 16px;
}
